
          @import "src/sass/global";
        
@import "LandingPages.module";

.hero {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(255,255,255,0.6);
  }
}

.heroContent {
  position: relative;
  padding: $layout-medium-padding;
  margin: 0 auto;
  font-weight: $typography-style-medium-bold;

  button {
    margin-top: $layout-medium-padding;
  }

  @include media('>=tablet') {
    max-width: $layout-max-section-width-narrow;
    flex-basis: 65%;
  }
}

.heroImage {
  display: none;

  @include media('>=desktop') {
    display: block;
    position: relative;
    flex-basis: 35%;
    height: 90vh;
    opacity: 0.8;
  }
}

section.partners {
  max-width: $layout-max-section-width;
  padding: $layout-medium-padding;
  margin: 0 auto;
  text-align: center;

  h2 {
    text-transform: uppercase;
  }
}

section.howItWorks {
  max-width: $layout-max-section-width;
  margin: 0 auto;

  h2, & > p {
    text-align: center;
    padding: 0 $layout-medium-padding;
  }

  h2 {
    text-transform: uppercase;
  }
}

section.testimonial {
  background-color: $blue-20;
  padding: $layout-medium-padding;

  .testimonialBody {
    max-width: $layout-max-section-width;
    margin: 0 auto;

    @include media('>=tablet') {
      padding: $layout-narrow-padding $layout-medium-padding;
    }
  }
}

section.cta {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  padding: $layout-medium-padding;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(255,255,255,0.3);
  }

  .ctaInnerBox {
    position: relative;
    text-align: center;
    padding: $layout-medium-padding;
    max-width: $layout-max-section-width-narrow;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: rgba(255,255,255,0.8);
    }

    .ctaContent {
      position: relative;
    }
  }
}

section.providerJoin {
  display: flex;
  flex-direction: column;
  // TODO: Find a better approach. This is to offset the margin from the footer.
  margin-bottom: -$layout-base-gap-wide;

  @include media('>=tablet') {
    flex-direction: row;
  }

  .contractorJoin, .clinicianJoin {
    position: relative;
    flex-basis: 50%;
    padding: $layout-medium-padding;
    @include media('>=tablet') {
      min-height: 40vh;
    }
  }

  .clinicianJoin {
    background-color: $blue;
  }

  .providerJoinContent {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
  }
}