section.features {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: $layout-medium-padding;
  gap: $layout-base-padding;
  max-width: $layout-max-section-width;
  margin: 0 auto;

  @include media('>=tablet') {
    flex-direction: row;
  }
}

.featuresImage {
  position: relative;
  order: 1;
  
  img {
    border-radius: $border-radius-default;
  }

  @include media('>=tablet') {
    flex-basis: 40%;
    order: 0;
  }
}

.featuresBody {
  display: flex;
  order: 2;
  flex-direction: column;
  justify-content: center;

  @include media('>=tablet') {
    flex-basis: 60%;
    order: 0;
  }
}

.featuresDescription {
  font-weight: $typography-style-medium-bold;
  margin: 0;
}

.sectionTitle {
  font-size: $typography-size-medium;
  font-weight: $typography-style-medium-bold;
  line-height: $typography-line-height-narrow;
  margin-top: 0;
  color: $blue;

  @include media('>=tablet') {
    font-size: $typography-size-large;
    font-weight: $typography-style-bold;
  }

  &.inverted {
    color: white;
  }
}

.sectionSubtitle {
  font-size: $typography-size-medium;
  font-weight: $typography-style-medium-bold;
  line-height: $typography-line-height-narrow;
  margin-top: 0;
  color: $gray-dark;

  @include media('>=tablet') {
    font-weight: $typography-style-bold;
  }
}
