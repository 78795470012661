@import 'include_media';
@import 'src/sass/partials/elevated_mixin';
@import 'src/sass/partials/utility_mixins';

@mixin typography-headings-base() {
  color: $black;
  font-family: var(--header-font), 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -1px;
}

@mixin typography-size-xxl() {
  font-size: rem(32px);
  line-height: rem(48px);
}

@mixin typography-size-xl() {
  font-size: rem(30px);
  line-height: rem(45px);
}

@mixin typography-size-large() {
  font-size: rem(26px);
  line-height: rem(39px);
}

@mixin typography-size-medium() {
  font-size: rem(22px);
  line-height: rem(33px);
}

@mixin typography-size-small-medium() {
  font-size: rem(22px);
  line-height: rem(33px);
}

@mixin typography-size-small() {
  font-family: var(--body-font), 'Roboto', sans-serif;
  font-size: rem(18px);
  line-height: rem(33px);
}

@mixin avatar() {
  //box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 2px 1px;
  object-fit: contain;
}

$html-font-size: 16px;

@function rem($size) {
  $remSize: $size / $html-font-size;
  @return #{$remSize}rem;
}

$text-color: #384354;

$neutral-inverted: #fff;

$input-disabled: #e1e1e1;
$gray-100: #f9f9f9;
$gray-150: #e8e8e8;
$gray-200: #4f4f4f;
$gray-dark: #61687a;
$gray6: #f2f2f2;
$black: #0d1216;
$black-400: #363b48;

$blue-light: #4768be;
$blue-light-1: $blue-light;
$panel-color: #f8f8f8;
$blue: #2b478b;
$blue-20: #e0e7ff;
$blue-background-light: #eaf3fb;

$cyan: #46d9e2;

$desire: #d23548;
$desire-light: #eb4559;

$topic-color: #8b202d;

$anchor-color: $blue;

$color-highlight-color: $anchor-color;

$button-standard-padding: 0.7rem 1.5rem;
$button-standard-min-width: 10em;

/* temp alias as we move all colors to the same variable namespace */
$color-red-50: $desire;

$color-gray-05: #f9f9f9;
$color-gray-10: #f0eeee;
$color-gray-20: $gray-150;
$color-gray-30: #d2d1d1;

$color-blue-10: #ebf3fa;
$color-blue-20: $panel-color;
$color-blue-50: $blue-light;
$color-blue-70: $blue;
$color-blue-90: #161631;

$color-green-50: #2f855a;
$color-green-10: #e0fbed;

$color-red-10: #ffe6e6;

$color-yellow-20: #fff0d9;

$color-purple-40: #3c3fc0;
$color-purple-50: #251e88;

$color-base-neutral-10: #e6e3e3;
$color-base-neutral-20: #d5cfcf;
$color-base-neutral-30: #bcb4b3;
$color-base-neutral-40: #a89e9c;
$color-base-neutral-50: #8d8583;
$color-base-neutral-60: #706968;
$color-base-neutral-70: #534e4d;
$color-base-neutral-80: #393534;
$color-base-neutral-90: #201d1c;

$color-neutral-10: #f5f5f5;
$color-neutral-50: #7e7e7e;
$color-neutral-70: $black;
$color-neutral-90: #171717;
$color-neutral-inverted: $neutral-inverted;

$color-dialog-backdrop: rgba(74, 82, 102, 0.8);
$color-dialog-background: white;

$color-message-box-error-background: #fae5e5;
$color-message-box-error-border: #5e2020;
$color-message-box-error-text: #2a0e0e;

$color-message-box-success-background: #e5fae5;
$color-message-box-success-border: #235e20;
$color-message-box-success-text: #133113;

$box-shadow-header-row: transparent 0 0, transparent 0 0, rgba(0, 0, 0, 0.15) 1px 3px 6px;
$box-shadow-button-row: 5px 5px 5px 5px $color-gray-30;
$box-shadow-elevated: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
$box-shadow-elevated-blue: 0 0 5px rgba(40, 67, 137, 0.5), 0 0 5px rgba(40, 67, 137, 0.5);
$box-shadow-elevated-subtle: 0 2px 11px 4px rgba(0, 0, 0, 0.03), 0 1px 2px rgba(0, 0, 0, 0.06);
$box-shadow-step-button: 0 2px 11px 4px rgba(0, 0, 0, 0.03), 0 0 9px rgba(0, 0, 0, 0.07);

/* BORDERS */
$border-light-thin: 1px solid $color-gray-20;
$border-desire-thin: 1px solid $desire;
$border-dark-medium: 2px solid $color-neutral-70;
$border-radius-default: 0.25rem;

$layout-narrow-padding: 0.5rem;
$layout-default-padding: 1rem;
$layout-base-padding: 1.5rem;
$layout-medium-padding: 2.5rem;
$layout-wide-padding: 4rem;

$layout-narrow-gap: 0.5rem;
$layout-base-gap: 1rem;
$layout-base-gap-medium: 1.5rem;
$layout-base-gap-wide: 2.5rem;
$layout-base-gap-xl-wide: 4rem;

$layout-max-modal-width: 64em;
$layout-max-section-width: 77.9375em;
$layout-max-section-width-narrow: 37.5em;
$layout-max-content-width: 87.5em;
$layout-max-modal-content: 31.25em;
$layout-max-input-length: 30em;

/* Blog: Used to indent content sections such as the
   hero, about, etc. */
$layout-section-inline-start-padding: 8rem;

$mq-tablet-and-up: screen and
  (
    min-width: 47.9375em,
  );
$mq-small-desktop-and-up: screen and
  (
    min-width: 63.9375em,
  );
$mq-large-desktop-and-up: screen and
  (
    min-width: 74.9375em,
  );

/* GLOBAL TYPOGRAPHY STYLE VARIABLES */
$typography-line-height-narrow: 1.2;
$typography-line-height-default: 1.5;
$typography-line-height-wide: 2;

$typography-letter-spacing-medium: 0.05rem;

$typography-style-bold: 700;
$typography-style-medium-bold: 600;
$typography-style-normal: 400;

$typography-size-display: rem(39.81px);
$typography-size-xxl: rem(33.18px);
$typography-size-xl: rem(27.65px);
$typography-size-large: rem(23.04px);
$typography-size-medium: rem(19.2px);
$typography-size-default: rem(16px);
$typography-size-small: rem(14px);

$typography-content-font-family: 'Inter', sans-serif;
$typography-headings-font-family: var(--header-font), 'Roboto', sans-serif;

$z-index-bring-to-front: 9999;
$z-index-on-top: 100;
$z-index-send-to-back: -1;

/* FORMS */
/* Used to wrap a label and input element */
.default-legend {
  font-size: $typography-size-xl;

  /* In some instances it is helpful to use a heading element
     inside the legend. In these instances, ensure that the
     heading element uses the following class. */
  .heading {
    margin-bottom: 0;
  }
}

.form-input-group {
  display: block;
  padding: $layout-narrow-padding 0 $layout-default-padding;

  label {
    display: block;
    margin-bottom: $layout-narrow-gap;
  }

  input,
  select {
    min-width: 21.25rem;
  }
}

.field-requirements {
  display: block;
  font-size: $typography-size-small;
}

/* Toast style messages */
.message-box {
  border: 2px solid $color-gray-30;
  max-width: max-content;
  padding: $layout-narrow-padding $layout-default-padding;
}

.message-box-success {
  background-color: $color-message-box-success-background;
  border: 2px solid $color-message-box-success-border;
  color: $color-message-box-success-text;
}

.message-box-error {
  background-color: $color-message-box-error-background;
  border: 2px solid $color-message-box-error-border;
  color: $color-message-box-error-text;
}

/* Utilities */
.no-border {
  border: 0;
}

.standard-content-padding {
  padding: $layout-base-padding;
}

.visually-hidden {
  @include visually-hidden();
}

/* We use display: none; because we _do_ want to hide the tab panel from 
   everyone _including_ screen readers. */
.is-hidden {
  display: none;
}

.desire-divider-after::after {
  background-color: $desire;
  content: '';
  display: block;
  height: 2px;
  margin: $layout-base-padding auto;
  width: 21px;
}

.simple-divider {
  border-bottom: 1px solid $color-gray-30;
  margin: $layout-base-padding auto;
}

@mixin ghost-button() {
  background: none;
  border: 0;
  cursor: pointer;
}

.ghost-button {
  @include ghost-button();
}
a.ghost-button {
  color: $black;
  text-decoration: none;
  &:visited {
    color: $black;
  }
}

@mixin btn-lock {
  width: rem(12px);
  height: rem(14px);
  position: absolute;
  left: 15px;
  top: 30%;
}

@mixin readable-line-length() {
  /* fallback value for browsers that do not support the `ch` unit */
  max-width: 60em;
  max-width: 100ch;
}

@mixin readable-line-length-narrow() {
  /* fallback value for browsers that do not support the `ch` unit */
  max-width: 50em;
  max-width: 80ch;
}

.readable-line-length {
  @include readable-line-length();
}

.readable-line-length-narrow {
  @include readable-line-length-narrow();
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes collapse {
  0% {
    transform: scaleY(1);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    height: 0;
    transform: scaleY(0);
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .fade-out {
    animation: fadeOut 300ms ease-in-out forwards;
  }
}

// animation for dropdown menus, place on target menu
@mixin grow-down() {
  @media (prefers-reduced-motion: no-preference) {
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
  }
}

@mixin collapse() {
  @media (prefers-reduced-motion: no-preference) {
    animation: collapse 300ms ease-in-out forwards;
    transform-origin: top center;
  }
}
