@mixin hr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@mixin hr-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* hides from everyone except screen readers */
@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* reverts the visually-hidden styling */
@mixin revert-visually-hidden {
  position: initial;
  width: initial;
  height: initial;
  margin: initial;
  padding: initial;
  overflow: initial;
  clip: initial;
  border: initial;
}
