
          @import "src/sass/global";
        
.partnerLogos {
  position: relative;
  padding: $layout-base-padding;
  white-space: nowrap;

  &:before, &:after {
    position: absolute;
    top: 0;
    content: '';
    width: 100px;
    height: 100%;
    z-index: 2;
  }

  &:before {
    left: 0;
    background: linear-gradient(to left, rgba(255,255,255,0), rgb(255, 255, 255));
  }

  &:after {
    right: 0;
    background: linear-gradient(to right, rgba(255,255,255,0), rgb(255, 255, 255));
  }

  img {
    filter: grayscale(100%);
    opacity: 0.7;
    transition: filter 0.3s, opacity 0.3s;

    &:hover {
      filter: none;
    }
  }
}

.logo {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 $layout-narrow-gap;
}

.imageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
