
          @import "src/sass/global";
        
.appContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.mainContent {
  max-width: 100%;
}
