
          @import "src/sass/global";
        
.announce {
  color: white;
  font-weight: 500;
  background-color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 8px;
}

.announceIcon {
  background-color: $desire;
  height: 37px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 12px;

  @include media('<tablet') {
    display: none;
  }
}

.text {
  text-align: center;
}

.announcementDescription {
  display: block;
}
