
          @import "src/sass/global";
        
.carouselWrapper {
  @include media('>=tablet') {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.carousel {
  @include media('>=tablet') {
    flex-basis: 80%;
  }
}

.sideControls, .bottomControls {
  color: $gray-dark;
  background: none;
  border: none;

  &[disabled] {
    color: $input-disabled;
  }
}

.sideControls {
  display: none;

  @include media('>=tablet') {
    display: inline-block;
    flex-basis: 10%;
  }
}

.bottomControlsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @include media('>=tablet') {
    display: none;
  }
}

.quotes.right {
  text-align: right;
}
